.titleHeader{
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #69789F;
}

.intervals{
    border:none !important
}