.divisionsContainer{
    background: linear-gradient(358.83deg, #572AD8 15.85%, #408BFB 92.68%);
    border-radius: 26px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 648px;
}

.profitContainer{
    background: #12948C;
    border-radius: 3px;
    padding: 4px 4px;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    color: #FFFFFF;
}

.lossContainer{
    background: #FC5980;
    border-radius: 3px;
    padding: 4px 4px;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    color: #FFFFFF;
}

.tabsWrapper {
    color: white;
    border-radius: 10px 10px 0px 0px !important;
    padding: 0.5rem 1rem 0 2rem;
}

.tabsListText{
    
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

}

.ant-picker {
    padding: 8px 11px 4px !important;
}

