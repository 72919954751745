.overviewContainer{

    background: #FFFFFF;
    border-radius: 28px;
}

.overviewHeader{
    background: #F9F9FF;
    border-radius: 28px 28px 0px 0px;
    padding: 90px 28px;

    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #69789F;
}

.title{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #9FADC7;
}

.text{
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    color: #383874;
}

.otherText{
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    /* identical to box height */
    color: #383874;
}
