.notificationsContainer{
    padding: 0px;
    background: #FFFFFF;
    border-radius: 28px;
}

.notificationsHeader{
    width: 100%;
    background: #F9F9FF;
    border-radius: 28px 28px 0px 0px;
    padding: 20px 28px
}

.notificationsHeading{
    
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    text-transform: capitalize;
    color: #69789F;  
}

.notificationsHeadingLink{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    display: flex;
    align-items: center;
    color: #2C67FF;
    border-bottom: 2px solid #2C67FF;
}
.notificationsTradeNumberPill{
    background: #E2E7FF;
    width:145px;
    border-radius: 6px;
    padding:3px 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #05172E;
}

.notificationsTime{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 17px;
    /* identical to box height, or 170% */
    color: #66687D;
}

.notificationsHr{
    border: 1px solid #EEEEEE;
    margin:10px
}