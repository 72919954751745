.upperHeadingTitle{
    font-family: 'Poppins';
    padding: 0;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    text-transform: uppercase;
    color: #62668B;
}

.upperHeadingText{
    font-family: 'Poppins';
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #4F5466;
}

.CodePill{
    background: #E2E7FF;
    border-radius: 6px;
    padding:3px
}

.CodePill p{
    margin: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */


    color: #05172E;
}

.seperator{
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #727272;
}