.greetingContainer{
    height: auto;
    left: 146px;
    top: 166px;
    background: linear-gradient(180deg, rgb(255, 255, 255) 40.28%, rgba(1, 247, 202, 0.44) 106.74%);
    border-radius: 28px;
    padding: 46px 35px;
}

.greetingHourText{
    
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #9FADC7;
}

.greetingNameText{
    
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;

    color: #69789F;
}

.greetingCounter{
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.01);
    border-radius: 28px;
}

.title{
    
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    /* identical to box height */
    color: #383874;
}

.text{
    
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #969FB6;
}

.greetingEarnedProfitTitle{
    
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;

    color: #9FADC7;
}

.greetingEarnedProfitAmount{
    
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    /* identical to box height */
    color: #383874;
}