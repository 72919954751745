.greetingCounter{
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.01);
    border-radius: 28px;
}

.title{
    
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    /* identical to box height */
    color: #383874;
}

.text{
    
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #969FB6;
}
.titleHeader{
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    /* identical to box height */
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #69789F;
}

