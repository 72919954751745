.counterContainer{

    background: #FFFFFF;
    border-radius: 28px;
    padding-bottom:21px
}

.title{
    /* font-family: 'Gilroy' !important; */
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    /* identical to box height */
    color: #383874;
}

.text{
    /* font-family: 'Gilroy' !important; */
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #969FB6;
}

.iconContainer{
    width: 303.3px;
    height: 224.09px;
    background: no-repeat;
    background-image: url('../../../../../public/assets/images/supports/counter-icon-container.svg');
}

