

.greetingContainer{
    background: #FFFFFF;
    border-radius: 28px;
}

.greetingsSubcontainer{
    padding: 46px 35px 0px
}
.greetingHourText{
    /* font-family: 'Gilroy'; */
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #9FADC7;
}

.greetingNameText{
    /* font-family: 'Gilroy'; */
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;

    color: #69789F;
}

.greetingEarnedProfitTitle{
    /* font-family: 'Gilroy'; */
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #9FADC7;
}

.greetingEarnedProfitAmount{
    /* font-family: 'Gilroy'; */
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    /* identical to box height */
    color: #383874;
}
.greetingEarnedProfitAmount span{
    /* font-family: 'Gilroy'; */
}
.dollarTextColor{
    color: #69789F !important;
  }

.lossIcon{
    padding: 6px 8px;
    background: #FC5980;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.profitIcon{
    padding: 6px 8px;
    background: #12948C;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.totalTradeRevenueAmount{
    /* font-family: 'Gilroy'; */
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    /* identical to box height */
    color: #383874;
}