* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}


body {
  color: #4F5466 !important;
}

.grey-color {
  background-color: #F8F9FB !important;
}

.login-wrapper {
  overflow: hidden;
}

.main-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.login-image-background {
  background: url("/public/assets/images/login-bg.svg");
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.css-1tu4ad6 {
  border-bottom: transparent !important;
  color: #fff;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background: rgba(0, 0, 0, 0.25) !important;
  border-radius: 25px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #fff !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;

  font-style: normal !important;
  font-weight: 500 !important;
}


.seperator {
  width: 1px;
  height: 30px;
  background: #D9D9D9;
}

/* Position style elements */
.pt-3-rem {
  padding-top: 3rem
}

.pt-1-rem {
  padding-top: 1rem
}

.pt-3-rem {
  padding-top: 3rem
}

.pt-100 {
  padding-top: 100px;
}

.pt-2-rem {
  padding-top: 3rem
}

.pt-10-rem {
  padding: 10rem 3rem !important;
}

.pt-1-5-rem {
  padding-top: 2rem
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-10-rem {
  padding-left: 10rem !important;
}

.plr-10-rem {
  padding: 0 10rem;
}

.p-0_5-rem {
  padding: 0.5rem !important;
}

.p-1-rem {
  padding: 1rem !important
}

.p-2-rem {
  padding: 2rem !important;
}

.p-3-rem {
  padding: 3rem !important;
}

.p-40 {
  padding: 40px !important;
}

.p-50 {
  padding: 50px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-md-40 {
  padding: 40px !important;
}

.mb-10-rem {
  margin-bottom: 10rem !important;
}

.mb-2-rem {
  margin-bottom: 2rem !important;
}

.mb-5-rem {
  margin-bottom: 5rem !important;
}

.inner-page-header {
  padding: 2rem 2rem 1rem 2rem !important;
  margin-top: 5rem !important;
}


.pl-10px {
  padding-left: 10px;
}

.mt-1-rem {
  margin-top: 1rem !important;
}

.m-1-rem {
  margin: 0.5rem !important
}

.ml-1-rem {
  margin-left: 1rem !important;
}

.mt-2-rem {
  margin-top: 2rem !important;
}

.mt-4-rem {
  margin-top: 4rem !important;
}

.mb-1-rem {
  margin-bottom: 1rem !important;
}

.mb-10 {
  margin-bottom: 5rem;
}

.mse-3 {
  margin: 0px 3px;
}

/* Common styles */
.width-100 {
  width: 100%;
  min-height: 100vh !important;
}

.bb-grey {
  border-bottom: 1px solid #E7E8EB;
  margin-bottom: 10px;
}

.bb-ficontact {
  border-bottom: 1px solid #E7E8EB;
}


.width-100l {
  width: 100%;
}

.table_heading {
  height: 45px !important;
}

.table_heading_padding {
  padding: 15px !important;
}

.font-weight-500 th {
  font-weight: 500 !important;
}

.trade_list td {
  height: 78px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.trade_list .ant-space-item {
  font-size: 28px !important;
  align-items: flex-start !important;
}

.border_table {
  border-bottom: 1px solid #EDEDEF;
}

.no-border {
  border: none !important;
}

input[type="file"] {
  display: none;
}

.react-datepicker__input-container .form_input {
  width: 100%;

}

.w-20px {
  width: 20px !important;
}

.w-60 {
  width: 60% !important;
}


.react-datepicker__input-container>.form_input {
  padding: 1.2rem 0.75rem;
  font-weight: 400 !important;
  color: #000000 !important;
  border-bottom: 1px solid #3a3a3a;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  border-bottom: 1px solid #3a3a3a !important;
}

.pointer {
  cursor: pointer;
}

.overflow-scroll {
  overflow-y: scroll !important;
}

.form_input,
.form_input_custom {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #000000 !important;
  position: static;
  font-weight: 500 !important;
  left: 0px;
  top: 0px;
  border: 1px solid #AFAFAF;
  box-sizing: border-box;
  border-radius: 13px !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.form_input_custom {
  width: 40% !important
}

.form_input_color_change {
  background-color: #2C67FF;
}

.form_input:focus-visible {
  outline: none !important
}

.form-control:focus,
.form-check-input:focus {
  box-shadow: none !important;
}

.input_error {
  color: red;
  font-size: 12px;
}

.error-text {
  color: red !important;
}

.rbc-toolbar {
  background-color: #fff !important;
  padding: 30px 0px;
}

.rbc-calendar {
  margin: 0 30px !important;
  background-color: #fff;
  padding: 30px;
}

.rbc-header {
  background-color: #F8F9FA !important;
  border: 1px solid #DCDFE3 !important;

}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.rbc-toolbar button:focus {
  color: #fff !important;
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}

.rbc-header {
  font-size: 14px !important;

  padding: 15px 15px !important;
  text-transform: uppercase;
  font-weight: 500 !important;
}


/* .rbc-today{
   background-image: url("assets/images/mainicons/bg-calendar.svg");
} */
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  border: 1px solid #2C67FF !important;
  box-shadow: none !important;
  background-color: #fff !important;
}

.rbc-toolbar button:hover {
  color: #373a3c !important;
  background-color: #fff !important;
  border: 1px solid #2C67FF !important;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  color: #fff !important;
  background-color: #2C67FF !important;
}

.br-10 {
  border-radius: 10px !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cecece;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.form-card-body {
  height: 50vh !important;
}

.form-card-footer {
  box-shadow: 0px -1px 0px #F6F6F6;
  height: 150px;
  background: #fff !important;
  position: absolute;
  bottom: 0;
  width: 100% !important;
}

.form-card-footers {
  box-shadow: 0px -1px 0px #F6F6F6;
  height: 150px;
  background: #fff !important;
  position: absolute;
  bottom: 52px;
  width: 100% !important;
}

.bg-F8F9FA {
  background: #F8F9FA !important;
}

.dotSep {
  content: "\2022";
  font-size: 18px;
  color: #9E9E9E;
}






/* Common font styles */

.font-normal {
  font-style: normal;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-16 {
  font-size: 16px !important;
}


.color-23 {
  color: #232323 !important;
}

.color-4F5466 {
  color: #4F5466 !important;
}

.color-707895 {
  color: #707895 !important;
}

.color-2C67FF {
  color: #2C67FF !important;
}

.color-2C2C2E {
  color: #2C2C2E !important;
}

.color-fff {
  color: #ffffff !important;
}

.color-585966 {
  color: #585966 !important;
}

.color-7350EA {
  color: #7350EA !important;
}

.color-697382 {
  color: #697382 !important
}

.tableHead {
  color: #697382 !important
}

.color-243448 {
  color: 243448 !important
}

/* Button styles */

.add-new-button {
  width: 180px;
  height: 41px;
  border: 1px solid #2C67FF !important;
  background: #fff !important;
  border-radius: 13px !important;
  color: #2C67FF !important;
}


.switch {
  border-radius: 16px !important;
  background-color: none !important;
  border-color: none !important;
}

.btn-secondary {

  background: #E2E7FF !important;
  border: 1px solid #E2E7FF !important;
  border-radius: 13px !important;
  color: #5974FA !important;
}

/* Login page styles */


.login-welcome-title {
  line-height: 19px;
  color: #004D71;
}

.login-image-background {
  background: url("/public/assets/images/login-bg.svg");
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-left-image {
  width: 80%;
}

.login-welcome-sub-title {
  color: #232323;
  line-height: 40px;
}

.login-forgot-password {
  color: #4D47C3;
}

.login-button {
  background: #232323 !important;
  border-radius: 6px !important;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 46px;
  border: none !important;
  color: #FFFFFF;
}

.login-button:focus {
  box-shadow: none !important;
}

.input_error {
  color: red;
  font-size: 12px;
}

/* Sidebar styles */
.sidebar-component {
  height: auto !important;
  background: #fff !important;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #383838 !important;
  padding: 2rem 1.5rem;
}

.sidebar-component {
  box-shadow: inset -1px 0px 0px #F6F6F6;
}

.pro-sidebar {
  max-height: 100% !important;
}

.sidebar-component .pro-sidebar-inner {
  background: #fff !important;
  max-height: 100% !important;
  position: fixed !important;
}

.sidebar-component .pro-menu .pro-menu-item>.pro-inner-item {
  margin-bottom: 0.8rem;
}

.sidebar-component .pro-menu .pro-menu-item>.pro-inner-item:hover {
  color: #2C67FF !important;
}

.sidebar-component .pro-menu .active .pro-menu-item>.pro-inner-item {
  color: #2C67FF !important;
  background: #E2E7FF;
  border-radius: 6px;
}

.sidebar-component .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  background: #fff !important;
}

.pro-sub-menu .pro-inner-list-item .pro-item-content {
  font-size: 12px !important;
}

.pro-sidebar .pro-menu a {
  color: #383838 !important;
  padding: 8px 10px 8px 10px;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
  overflow: inherit !important;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
  border: none !important;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  background-color: transparent !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  margin-right: 0px !important;
}

.menu-item-active {
  background: #E2E7FF;
  border-radius: 6px;
}

.menu-item-active .pro-item-content .active {
  color: #2C67FF !important;
}

/* Navbar styles */

.navbar {
  /* height: 75px; */
  background: #fff;
  position: fixed !important;
  z-index: 99;
  justify-content: flex-start !important;
  border-bottom: 1px solid #E7E8EB !important;
}


/* Tabs style */
.react-tabs__tab-list {
  border: none !important;
  margin-bottom: 0px !important;
}

.react-tabs__tab-list .react-tabs__tab--selected {
  border-bottom: 3px solid #7350EA !important;
  color: #7350EA !important;
  background: none !important;
  font-weight: 600 !important;
}

.properties_list_card .react-tabs__tab {
  padding: 10px 8px !important;
}

.react-tabs__tab:focus:after {
  background: none !important;
}

.react-tabs__tab {
  background: #ffffff;
  border: none !important;
  border-bottom: 3px solid #eaeaea !important;
  box-sizing: border-box;
  margin-right: 4px;
  font-weight: 400 !important;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  padding: 16px 28px !important;
  color: #545561;

}

/* Inner pages styles */
.inner-wrapper {
  background-color: #F9F9FF;
  height: 100% !important;
}

.comp-color {
  background-color: #F9F9FF;
}

.dashboard-background {
  background-color: #EEEFFF;
  height: 100% !important;
}


/* Inner pages styles */


.tabs-wrapper {
  background: #FFFFFF;
  border-radius: 10px 10px 0px 0px !important;
  padding: 0.5rem 1rem 0 2rem;
}

.tabs-wrap-profile {
  background: #FFFFFF;
  border-radius: 10px 10px 0px 0px !important;

}


.card {
  border: none !important;
}

.tabs-content-wrapper {
  height: 100vh;
}

/*Customer styles */
.customer_supplier_row {
  height: 65px !important;
}

.contact-form-primary-badge {
  width: 76px;
  height: 34px;
  left: 1222px;
  top: 387.2px;
  background: rgba(84, 41, 255, 0.1);
  border-radius: 20px;
  padding: 8px 5px;
  margin-top: 4px;
  color: #5429FF
}

.disabled-contact-form {
  color: #A7B3C3 !important;
}

.list-id-pill {
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #5429FF;
  background: rgba(84, 41, 255, 0.1);
  border-radius: 20px;
  padding: 10px 15px;
}

/* Products styling */



.add_product_card {

  padding: 2rem 2rem;

}

.property_info_modal .modal-content {

  border-radius: 14px !important;

  border: none !important
}
@media (max-width: 1400px) {
  .landing_top_image_right{
    display: none !important;
  }
  .resp_text{
    width: 60% !important;
  }
}
@media (max-width: 1000px) {
  .landing_small_text{
    display: none !important;
  }

}

@media (min-width: 576px) {

  .modal_35 {
    max-width: 35% !important;
  }

  .modal_65 {
    max-width: 65% !important;
  }
}

.product_list_row {

  height: 72px;

}

.product_img_wrapper {

  background: #F8FAFB !important;
  width: 100%;
  min-height: 180px;
  color: #5429FF;
  padding: 10px 15px;
  border-radius: 10px;
}



@media only screen and (min-width: 576px) {
  .modal-dialog-custom-width {
    max-width: 900px !important;
  }

  .modal-dialog-custom-width-payments {
    max-width: 600px !important;
  }

  .edit-supply-plan {
    max-width: 900px !important;
  }
}

.trade_existing_customer .modal-content,
.payment-method-modal,
.payment-method-modals .modal-content,
.bank-account-modal .modal-content,
.add-user-modal .modal-content,
.trade_list_modal .modal-content {
  height: 100vh !important;
  border: 0 !important;
  border-radius: 0px !important;
}

.modal-content {
  height: 100%
}

.view_checklist .modal-content {
  height: 100% !important;
}

.edit-supply-plan .modal-content {
  height: 400px !important
}

/* Trade enquiry */

@media (min-width: 576px) {
  .trade_existing_customer {
    max-width: 60% !important;
    left: 20% !important;
  }

  .trade_list_modal {
    max-width: 100% !important;
    left: 10% !important;
  }

  .payment-method-modal {
    max-width: 30% !important;
    left: 35% !important;
    margin: 0 auto !important
  }

  .payment-method-modals {
    max-width: 50% !important;
    left: 25% !important;
    margin: 0 auto !important
  }

  .bank-account-modal {
    max-width: 50% !important;
    left: 25% !important;
    margin: 0 auto !important
  }

  .add-user-modal {
    max-width: 40% !important;
    left: 30% !important;
    margin: 0 auto !important
  }

  .trade_contract {
    max-width: 70% !important;
    left: 15% !important;
    margin: 0 auto !important
  }
}

@media (max-width: 576px) {
  .mt-10r {
    margin-top: 9rem !important;
  }

  .meeting_inner_left_info {
    background: #fff;
    border: 1px solid #EDEFF2;
    border-radius: 10px 10px 0px 0px !important;
    padding: 2rem 2rem;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #2C67FF;
    width: 40% !important;
  }

  .ag-chart-wrapper canvas {
    width: 400px !important;
    margin-top: -17px !important;
    position: relative;
    height: auto;
  }

  .meeting_innner_info {
    margin: 3rem 1.5rem 0 1.5rem !important;
  }

  .meeting_vew_top_header {
    background: #FFFFFF;
    padding: 2rem 3rem !important;
  }

  .meeting_info_scroll {
    height: calc(100vh - 300px);
    overflow-y: scroll;
  }


}

.trade_existing_customer {
  overflow-y: scroll;
}

.trade_existing_customer_modal_footer {
  height: 150px;
  background: #fff !important;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100% !important;
}

.edit-supply-plan-modal-footer {
  height: 100px;
  background: #fff !important;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100% !important;
}

.product_data_quality {
  background: #F8F9FB;
}




/* --- Ajmal --*/




.btn-outline-primary:hover {
  background-color: #fff !important;
  border-color: #2C67FF !important;
  color: #2C67FF !important;
}



.badgesize {
  width: 24% !important;
}


.tCustom>thead>tr>th {
  font-size: 13px;
  padding: 16px 16px;
  background: #F8F9FB !important;
  color: #4F5466;
  font-weight: 500;

}


.tCustom>thead,
tbody,
tfoot,
tr,
td,
th {

  padding: 16px 16px !important;
  border-bottom: 1px solid #E7E8EB !important;
  border-top: none !important;
}

.table> :not(caption)>*>* {

  border-bottom-width: 0 !important;
}

.cusname {

  font-size: 14px;
  font-weight: 600;

}

.cusaddress {
  font-weight: 400 !important;
  line-height: 20.8px;
}

.cusweight {

  font-weight: 400 !important;
  line-height: 20.8px;
}

.fonweight {

  font-weight: 500 !important;
  line-height: 20.8px;
}


.cusno {
  font-weight: 500 !important;
  line-height: 20.8px;
}

.ant-dropdown-menu-title-content {
  padding: 8px 8px !important;
}

.ant-dropdown-menu-item:hover {
  font-weight: 500 !important;
  transition-delay: 0.1s;
}



.ant-dropdown-menu {

  border-radius: 14px !important;

}

.disabled_badge {
  background: #E7E8EB;
  border-radius: 20px;
  font-weight: 500;
  width: auto;
  color: #A7B3C3;
  padding: 12px 14px;
  gap: 10px;
}

.disabled_badge::after {}

.status_badge {
  background: rgba(84, 41, 255, 0.1);
  border-radius: 20px;
  font-weight: 500;
  width: auto;
  color: #5429FF;
  padding: 12px 14px !important;

}

.add-edit-wrapper {
  padding: 0 2rem;
  background: #f9f9ff;
  width: 100%;
}

.inner-page-titleicon {
  height: 22px !important;
}

.br-8 {
  border-radius: 8px !important;
}

.br-4 {
  border-radius: 4px !important;
}

.mr-20 {
  margin-right: 20px;
}

.ml-10 {
  margin-left: 10px !important;
}

.text-right {
  text-align: right !important;
}

.br-6 {
  border-radius: 6px !important;
}

.btn-outline-primary:focus {
  box-shadow: none !important;
}


.upload-document-button {
  padding: 16px 26px !important;
  width: -webkit-fill-available;
  background: #D8E5FF !important;
  border: 1px dashed #2C67FF !important;
  border-radius: 13px !important;
  width: -moz-available;

}

.form-control {

  border: 1px solid #a7a7a7 !important;
  background-color: #FCFEFF !important;
  border-radius: 13px !important;
  color: #4F5466 !important;
}

.form-select {

  border: 0.8px solid #A7A7A7 !important;
  border-radius: 13px !important;

}

.btn-outline-secondary {
  border: 1px solid #2C67FF !important;
  background: #fff !important;
  border-radius: 13px !important;
  color: #2C67FF !important;

}

.btn-outline-secondary:focus {
  box-shadow: transparent !important;
}

.btn-primary {

  border-radius: 13px !important;
}


.btn-lg {
  padding: 16px 28px !important;
}

.form-control:disabled,
.form-control[readonly] {

  background-color: #E7E8EB !important;

}

.color-document {
  color: #2C67FF !important;
}

.form-check-input {
  width: 1.2em !important;
  height: 1.2em !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pcustom>tbody>tr {

  padding: 0px 0px !important;

}

.trade-inquiry-payment-method-container {
  width: 340px
}

.trade-enquiry-payment-method-inner-wrapper {
  background-color: #F8F9FB;
  border-radius: 6px;
}



.input_textarea {
  height: fit-content
}

.img-thumbnail {
  background-color: #fff;
  border-radius: 0.25rem;
  max-width: 100% !important;
  height: auto;
  border: 0px solid #dee2e6;
}

.iconDatePicker,
.laycan-date {
  position: relative;
}

.iconDatePicker img {
  position: absolute;
  z-index: 10;
  right: 35px;
  top: 34px
}

.laycan-date img {
  position: absolute;
  z-index: 10;
  top: 40px;
  right: 30px;
}

.border-bottom-1 {
  border-bottom: 1px solid #E7E8EB !important;
}

.pl-80 {

  padding-left: 80px !important;
}

.modal-dialog {

  margin: 0px auto !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.tab-width {
  width: 100% !important;
  border-bottom: 3px solid #E7E8EB !important;
  padding: 16px 28px !important;
  list-style: none !important;
  cursor: pointer !important;

}

:active,
:focus {
  outline: none;
  -moz-outline-style: none !important;
}

:link:focus,
:visited:focus {
  outline: none !important;
}

.tabs-scroll {

  overflow-y: auto;
  position: sticky;
  max-height: calc(100vh - 300px);
}

.contract-wrapper {

  position: fixed;
}

.editor-width {

  width: 85% !important;
}

.rounded-13 {
  border-radius: 13px !important;
}

.service-request-modal-wrapper {
  max-height: calc(100vh - 300px);
}

.View-Checklist-modal-wrapper {
  max-height: calc(100vh - 260px);
}


.card-height {

  min-height: 1400px !important;
  overflow-y: auto !important;
}

.LabelText {
  color: #4F5466 !important;
}

.badge-size {
  width: fit-content !important;
}

.pl-92 {
  padding-left: 92px !important;
}

.mb-3-rem {
  margin-bottom: 3rem !important;
}


.form-check-label {
  padding-left: 0.4rem !important;
}



.uploadText {

  color: #2C67FF !important;
}

.cursor-pointer {
  cursor: pointer;
}

.view_checklist {
  max-width: 50% !important;
  left: 25% !important;
  margin: 0 auto !important
}

.swal2-styled.swal2-confirm {

  background-color: #2C67FF !important;
}

.colorTerms {
  color: #243448;
}



.table-striped>tbody>tr:nth-of-type(even)>* {
  --bs-table-accent-bg: #FFF8FA !important;
  color: var(--bs-table-striped-color);
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #FFFff !important;
  color: var(--bs-table-striped-color);
}


/* @author Sajith */

.bg-light {
  background-color: #F8F9FB;
}

.bg-lighter {
  background-color: #F9F9FF;
}

.right-auto {
  right: auto !important;
}

.left-auto {
  left: auto !important;
}

.min-height-1150 {
  min-height: 1150px !important;
}

.colorTableHeading {
  color: #374558;
}



.confrimLabel {
  background: #EBF9F4;
  border: 1px solid #2FB182;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 500;
  color: #2FB182 !important;
  padding: 4px 12px !important;
}

.table-ttile-color {
  color: #4F5466;
}

.tradeTrsactionsHeading {
  color: #4F5466;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
}

.tradeTrsactionsSubHeading {
  font-style: normal;
  line-height: 18px;
  color: #4F5466;
}

.tradeTrsactionsid {
  color: #4F5466;
  font-style: normal;
  font-weight: 600;
  font-size: 10px !important;
  line-height: 15px;
}

.statBg {
  background-color: #E2E7FF;
  border-radius: 6px;
  padding: 3px 7px;
}

.ml-20 {
  margin-left: 20px;
}


.btn-info:hover {
  background-color: #1B56EF;
  border-color: transparent;
}

.table-ttile-color-sub {
  color: #66687D;
}

.btn-info:focus {
  box-shadow: transparent !important;
}

.textHead {
  color: #243448;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.position-fixed {
  position: fixed !important;
}


/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.trade-initiation-status-pill-pending {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 0 3px 0;
  gap: 10px;
  width: 78px;
  height: 21px;
  background: #FFF7E6;
  border: 1px solid #FFAE00;
  border-radius: 50px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  font-feature-settings: 'salt' on;
  color: #FFAE00;
}

.trade-initiation-status-pill-approved {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 0 3px 0;
  width: 78px;
  height: 21px;
  background: #EBF9F4;
  border: 1px solid #2FB182;
  border-radius: 50px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: flex-end;
  font-feature-settings: 'salt' on;
  color: #2FB182;

}

.pr-0 {
  padding-right: 0 !important;
}

.editProduct .modal-content {
  padding: 1.5rem !important;
}

.idStatus {
  background: rgba(84, 41, 255, 0.1);
  border-radius: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 8px 12px;
  color: #5429FF;
}

/* .btn-outline-secondary:focus{
  box-shadow: none !important; 
} */

.textPrimary {
  color: #232735 !important;
}

.titilePrimary {
  color: #383838;
}

.textSecondary {
  color: #62668B !important;
}

.text-center {
  text-align: center !important;
}



.btn-outline-secondary:focus {
  box-shadow: none !important;
}


.approval_in_process {
  padding: 4px 12px !important;
  background: #F9F3EB;
  border: 1px solid #FF9C01;
  border-radius: 50px;
  color: #FFAE00;
  font-size: 12px;
  width: fit-content !important;
}

.approval_pending {

  padding: 4px 12px;
  background: #E7E8EB;
  border: 1px solid #A7B3C3;
  border-radius: 50px;
  color: #A7B3C3;
  font-size: 12px;
  width: fit-content !important;

}

.approved {
  background: #EBF9F4;
  border: 1px solid #2FB182;
  border-radius: 50px;
  padding: 4px 12px;
  font-size: 12px;
  color: #2FB182;
  width: fit-content !important;
}

.btn-primary:focus {
  box-shadow: none !important;
}

@media only screen and (min-device-width: 419px) {

  .inner-page-header {}
}


.document_badge {
  background: #fff;
  border: 1px solid #CFCFCF;
  border-radius: 8px;
  padding: 5px 8px;
}

.mr-10 {
  margin-right: 10px !important;
}

.styleTitle {
  font-size: 12px;
}

.swal2-title {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #2C2C2E !important;

}

.payment-terms-text {

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  text-transform: capitalize;
  /* Gray/2 */
  color: #0a0aeb;
}

.payment-terms-width {
  width: 100px !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  /* identical to box height */
  text-transform: capitalize !important;
  /* Gray/2 */
  color: #2C2C2E !important;
}

.payment-method-pill {

  background: #E2E7FF;
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-transform: capitalize;
  color: #2C2C2E;
  padding: 5px 10px
}

.back-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;
  background: #FFFFFF;

  border-radius: 6px;

}

.timeline_steps {
  width: 34.52px;
  height: 34.52px;
  left: 0px;
  top: 117.98px;
  border: 1px solid #7350EA;
  border-radius: 50%;
  padding: 1rem;
}

.customer-invoice-text {
  width: 238.32px;

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #383838;
}

/* Datepicker css */
/* @author SAJITH date 09/09/2022*/

.react-datepicker {
  background: #FFFFFF !important;
  border: 1px solid #EBEBEB !important;
  box-shadow: 0px 4px 32px rgba(170, 170, 170, 0.03) !important;
  border-radius: 12px !important;
  padding: 20px !important;
}

.react-datepicker__header {
  background-color: #fff !important;
  padding-bottom: 15px !important;
  border-bottom: 1px solid #fff !important;
}

.react-datepicker__day {
  color: #666666;
  font-size: 13px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000 !important;
  padding-bottom: 10px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 50% !important;
  border-color: transparent !important;
}

.react-datepicker__day--in-range {
  background-color: #1d5d90;
  border-radius: 50% !important;
  border-color: transparent !important;
}

.react-datepicker__navigation--previous {
  left: 20px !important;
  top: 10% !important;
}

.react-datepicker__navigation--next {
  right: 20px !important;
  top: 10% !important;
}


/* Datepicker css */
/* @author SAJITH date 09/09/2022*/

.react-datepicker {
  background: #FFFFFF !important;
  border: 1px solid #EBEBEB !important;
  box-shadow: 0px 4px 32px rgba(170, 170, 170, 0.03) !important;
  border-radius: 12px !important;
  padding: 20px !important;
}

.react-datepicker__header {
  background-color: #fff !important;
  padding-bottom: 15px !important;
  border-bottom: 1px solid #fff !important;
}

.react-datepicker__day {
  color: #666666;
  font-size: 13px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000 !important;
  padding-bottom: 10px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 50% !important;
  border-color: transparent !important;
}

.react-datepicker__day--in-range {
  background-color: #1d5d90;
  border-radius: 50% !important;
  border-color: transparent !important;
}

.react-datepicker__navigation--previous {
  left: 20px !important;
  top: 10% !important;
}

.react-datepicker__navigation--next {
  right: 20px !important;
  top: 10% !important;
}


/* .invoiceTable>thead, tbody, tfoot, tr, td, th {
  padding: 16px 16px !important;
  border-top: none !important;
} */
.pill_count {
  padding: 2px 4px;
  background: #7350EA;
  font-size: 10px;
  color: #fff;
  border-radius: 50%;
}

.bottom_border {
  padding-bottom: 10px;
  border-bottom: 1px solid #D8E5FF
}

.notify_count {
  background: #E2E7FF;
  border-radius: 8px;
  width: 34px;
  height: 34px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.01em;

  /* Active-color */

  color: #2C67FF;
  padding: 5px 9px;
}

.outline-black {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  width: 100%;
  color: #4F5466 !important;
  align-items: center;
}

.profile_icon {
  width: 80px;
  padding: 5% 6%;
  border-radius: 50%;
  background: #2C67FF;
  color: #fff;
}

.avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* background: rgb(240,184,247);
    background-color: #a5f2e4; */
  background-image: -webkit-linear-gradient(199deg, #a5f2e4 0%, #e1fdcd 100%);
  background-image: -moz-linear-gradient(199deg, #a5f2e4 0%, #e1fdcd 100%);
  background-image: -o-linear-gradient(199deg, #a5f2e4 0%, #e1fdcd 100%);
  background-image: linear-gradient(199deg, #a5f2e4 0%, #e1fdcd 100%);
  color: #232323;
}

.avatar-secondary {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #8effd5;
  background-image: -webkit-linear-gradient(0deg, #8effd5 0%, #28fff6 100%);
  background-image: -moz-linear-gradient(0deg, #8effd5 0%, #28fff6 100%);
  background-image: -o-linear-gradient(0deg, #8effd5 0%, #28fff6 100%);
  background-image: linear-gradient(0deg, #8effd5 0%, #28fff6 100%);

  color: #232323;
}

.avatar-add-user {
  width: 58px;
  height: 58px;
  padding: 5% 6%;
  border-radius: 50%;
  background-image: -webkit-linear-gradient(199deg, #a5f2e4 0%, #e1fdcd 100%);
  background-image: -moz-linear-gradient(199deg, #a5f2e4 0%, #e1fdcd 100%);
  background-image: -o-linear-gradient(199deg, #a5f2e4 0%, #e1fdcd 100%);
  background-image: linear-gradient(199deg, #a5f2e4 0%, #e1fdcd 100%);
  color: #232323;
}

.active_user {
  width: 80px;
  padding: 4px 12px;
  background: #EBF9F4;
  border: 1px solid #2FB182;
  border-radius: 50px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #2FB182;
}

.userrole {
  background: #EEEAFF;
  border-radius: 8px;
  width: 200px;
  padding: 4px 12px;
  font-weight: 500 !important;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  color: #5429FF;
}

.useremail {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #4F5466;
}

.division-listin-process-pill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  background: #EEEAFF;
  border: 1px solid #EEEAFF;
  border-radius: 50px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #05172E;
}

.multiSelectContainer {
  border-radius: 0px;
}

.lock_unlock {
  width: 8%;
}

.loader_class {
  width: 50px;
  height: 50px !important;
}

.meetings_header {
  background: #FFFFFF;
  border-width: 1px 0px;
  border-style: solid;
  height: 105px;
  border-color: #EDEFF2;
  width: 100%;
  border-top: 1px solid #EDEFF2;
  box-shadow: -4px -2px 40px 15px rgba(0, 0, 0, 0.05);
  margin-top: 4.7rem;
}

.meeting_header_date {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #3B3E45;
}

.meeting_date_select {
  margin-left: 5px;
}

.meeting_date_select .react-datepicker-wrapper .react-datepicker__input-container input[type='text'] {
  display: none;
}

.choose_type {
  border: 1px solid #DCDFE3;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin-left: 10px;
  width: 120px;
  height: 40px;
}

.view_type {
  border: 1px solid #DCDFE3;
  width: 135px;
  height: 40px;
}

.filter_meeting {
  border: 1px solid #DCDFE3;
  padding: 0.5rem 1rem;
  margin-left: 10px;
  border-radius: 4px;
}

.meeting_term {
  color: #000;
}

.create_meeting_button_end {
  background: #1A56F0;
  border-radius: 0px 4px 4px 0px;
  padding: 8px 8px;
}

.create_meeting_button {
  padding: 0 0 0 0.5rem !important;
  border-radius: 0px !important;
  font-size: 14px !important;
}

.rejected {

  padding: 4px 12px !important;
  background: red;
  border: 1px solid red;
  border-radius: 50px;
  color: #fff;
  font-size: 12px;
  width: fit-content !important;
}

.tab-widths {
  border-bottom: 3px solid #E7E8EB !important;
  cursor: pointer !important;
  list-style-type: none !important;
}

.sub_heading {
  color: #9095A2;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.br-right {
  border-right: 1px solid #EFEEF1;
  padding: 0 !important;
}

.brd-color {
  color: #DADCE0;
}

.trade_list_modal .modal-content {
  background: #F9F9FF;
  overflow: hidden;
}

.signed_document {
  padding: 2rem 1rem;
  background: #F8F9FB;
  border: 1px solid #F6F6F6;
  border-radius: 8px;
  margin-bottom: 20px;
}



.ant-steps-item-tail {
  position: absolute;
  top: 10px !important;
  left: 17px;
  height: auto !important;
  padding: 0px 0 6px;
  background-color: #000000;
}




.ant-steps-vertical>.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail {
  position: absolute;
  top: 10px !important;
  left: 17px;
  height: auto !important;
  padding: 10px;
  background-color: transparent !important;
  border: 1px dotted rgba(255, 174, 0, 1);
}

.ant-steps-vertical>.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail {
  position: absolute;
  top: 10px !important;
  left: 17px;
  height: auto !important;
  padding: 10px;
  background-color: transparent !important;
  border: 1px dotted #A7B3C3;
}

.ant-steps-vertical>.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail {

  position: absolute;
  top: 10px !important;
  left: 17px;
  height: auto !important;
  padding: 0px 0 6px;
  background-color: #2FB182;



}

.ant-steps-item-container {
  display: flex;
  align-items: center;
}


.organisational-settings-bank-account-listing {
  box-sizing: border-box;
  background: #FFFFFF;
  /* Line */
  border: 1px solid #E7E8EB;
  border-radius: 10px;
  padding: 24px 34px;
}

.privileges-permission-box {
  box-sizing: border-box;
  background: #FFFFFF;
  /* Line */
  border: 1px solid #E7E8EB;
  padding: 18px 14px;
}

.organisational-settings-bank-account-listing {
  box-sizing: border-box;
  background: #FFFFFF;
  /* Line */
  border: 1px solid #E7E8EB;
  padding: 18px 14px;
}


.company-logo-organisation-settings {
  width: 150px;
  height: auto;
  background: #ffffff;
}

.trade_process_tabs .react-tabs__tab-list .react-tabs__tab--selected {
  border-bottom: 1px solid #7350EA !important;
  border-left: 1px solid #7350EA !important;
  border-top: 1px solid #7350EA !important;
  color: #5B656D !important;
  background: #fff !important;
  font-weight: 600 !important;
  padding: 25px 40px !important;
  border-radius: 0 !important;
}

.contract_tabs .react-tabs__tab-list .react-tabs__tab--selected {
  border-bottom: 1px solid #7350EA !important;
  border-left: 1px solid #7350EA !important;
  border-top: 1px solid #7350EA !important;
  color: #5B656D !important;
  background: #fff !important;
  font-weight: 600 !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0 !important;
}

.contract_tabs .tab-width {
  width: 100% !important;
  border-bottom: 3px solid #E7E8EB !important;
  list-style: disc !important;
  cursor: pointer !important;
  padding: 0.5rem 1rem !important;
}

.tab-width {
  width: 100% !important;
  border-bottom: 3px solid #E7E8EB !important;
  list-style: disc !important;
  cursor: pointer !important;
  padding: 25px 40px !important;
}


.tab_completed {
  border-bottom: 3px solid #EFEEF1 !important;
  color: #5B656D !important;
  background: #fff !important;
  font-weight: 600 !important;
  padding: 25px 40px !important;
}


.tab_sub_heading {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #9095A2;
}

.trade_process_card_footer {
  width: auto !important;
}

.scroll-custom {
  max-height: 100vh !important;
  height: calc(100vh - 560px) !important;
  overflow: scroll;
}

.tradeTrasactionFooter {
  padding: 30px 20px;
  left: 4.5% !important;
}

.avatar_trade {
  width: 38px;
  height: 38px;
  padding: 4% 5%;
  border-radius: 50%;
  background: linear-gradient(337.29deg, #EBFFD1 16.45%, rgba(196, 225, 174, 0.45) 77.87%);
  /* background: linear-gradient(346deg, rgba(240,184,247,1) 0%, rgba(45,70,253,1) 60%); */
  color: #4F5466;
}

.approved_badge {
  background: #EBF9F4;
  border: 1px solid #2FB182;
  border-radius: 20px;
  padding: 4px 12px;
  font-size: 12px;
  color: #2FB182;
  width: 100px !important;
  height: 30px !important
}

.rejected_badge {
  background: #FDF4F6;
  border: 1px solid #DE486C;
  border-radius: 20px;
  padding: 4px 12px;
  font-size: 12px;
  color: #DE486C;
  width: 100px !important;
  height: 30px !important
}

.bankstatus-pill-active {
  text-align: center;
  padding: 3px 14px;
  background-color: rgba(101, 177, 104, 0.41);
  border-radius: 50px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height */
  text-transform: uppercase;
  color: #40A444;
}

.bankstatus-pill-inactive {
  text-align: center;
  padding: 3px 14px;
  background: #FCC2D0;
  border: 1px solid #DE486C;
  color: #DE486C;
  border-radius: 50px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height */
  text-transform: uppercase;
  font-feature-settings: 'salt' on;
}

.border-bottom-primary {
  border: 1.5px solid #1890ff;
  padding: 12px 17px;
  border-radius: 8px;
}

.text-orange {
  color: #FCA726;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.py-5 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.colorUserName {
  color: #5B656D;
}

.textHeaderTab {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}


.center-img {

  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}

.group_heading {
  background: #DAF8FF !important;
  padding: 1rem 3rem !important;
  width: 100% !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #707895;
}

.w-16 {
  width: 16% !important;
}

.w-18 {
  width: 18% !important;
}

.w-20 {
  width: 20% !important;
}

.w-28 {
  width: 28% !important;
}


.headingBg {
  position: fixed;
  background: #DAF8FF;
  width: 19.8%;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 20px 25px;
}

.fixed-bottoms {
  position: absolute;
}

.bottom-fixed {
  position: fixed;
  bottom: 0;
  z-index: 1030;
}

.headingTab {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.h-100vh {
  height: 100vh !important;
}

.h-100 {
  height: 100% !important;
}

.trade_process_tabs ::before {
  list-style-type: none !important;
  /* background-color: red; */
}

.scrollLcustom {
  overflow: scroll;
  height: 700px;
  max-height: 100vh;
}

.meetings_tab .react-tabs__tab-list .react-tabs__tab--selected {
  color: #fff !important;
  background: #7350EA !important;
  border-radius: 30.5px;
  font-weight: 600 !important;
  width: 120px;
  text-align: center;
}

.meetings_tab .react-tabs__tab {
  background: #F9F9FB !important;
  border-radius: 30.5px;
  border-bottom: none !important;
  border: 1px solid #EAEAEA !important;
  color: #313350;
  width: 120px;
  text-align: center;
}

.border_bottom_input .form-control {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid #ced4da !important;
}

.bgMeeting {
  background-color: #EAEAEA;
  padding: 20px 15px;
  margin-right: 20px;
  border-right: 1px solid #EAEAEA;
}

.accordion-button {
  padding: 0px !important;
}

.accordion-button::after {
  margin-right: 20px !important;
}


/* mom styles */
.br-0 {
  border-radius: 0 !important;
}

.tableMeeting>thead>tr>th {
  color: #62668B;
  font-size: 12px !important;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 500;
  background: #F9F9FB;
}

.tableMeeting>thead,
tbody,
tfoot,
tr,
td,
th {

  padding: 16px 16px !important;
  border-bottom: 1px solid #E7E8EB !important;
  border-top: none !important;
}

.bgMeetingShadow {
  background: #FFFFFF;
  border-top: 1px solid #EDEFF2;
  box-shadow: -4px -2px 40px 15px rgba(0, 0, 0, 0.05);
}

.border-right-0 {
  border-right: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.calenderIcon {
  width: 20px;
  height: 20px;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: -24px;
  z-index: 9988;
  position: relative;
}


.form-select:focus {
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%) !important;
}

.scrollContract {
  height: 800px;
  overflow: scroll; 
  max-height: 100vh;
}

.scrollContracts {
  height: 800px;
  overflow-y: scroll; 
  max-height: 100vh;
}


.subsScroll{
  overflow-y: scroll;
  max-height: 100vh;
}

.scrollMeet {
  overflow: scroll;
  max-height: calc(100vh - 220px) !important;
}

.imguserIcon {
  width: 20px;
  height: auto;
}

.text-right {
  text-align: right !important;
}

.addUserList>thead>tr>th {
  background: #F8F9FB !important;
  color: #4F5466;
  font-weight: 500;
  font-size: 13px;
}

.count_badge {
  padding: 8px 12px;
  background: rgba(84, 41, 255, 0.1);
  border-radius: 8px;
  margin-left: 6px;
}

/* .userList>thead, tbody, tfoot, tr, td, th {
  padding: 10px 8px !important;
  border-top: none !important;
} */
.border-0 {
  border: 0px !important;
}



/* .multiSelectContainer {
  border: 0px !important;
} */

.sticky-top {

  top: 60px !important;

}

.inner-page-content {

  padding: 1rem 2rem 2rem 2rem !important;
}

.inner-page-contents {
  padding: 2rem 0rem 0rem 0rem !important;
}



.trade_list_modal .modal-header {
  display: block !important;
}

.meeting_type {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4F5466;
}

.meeting_list_title {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #4F5466;
}

.meeting_list_bold {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #4F5466;
}

.meeting_task_title {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #4F5366;
}

.task_div {
  padding: 10px 5px;


}

.meeting_task_due_date .react-datepicker__input-container>.form_input {
  padding: 10px !important
}


.custom-tab>.react-tabs__tab-list .react-tabs__tab--selected {
  border-bottom: none !important;
  color: white !important;
  background: #07a2f0 !important;
  font-weight: 600 !important;
}



.custom-tab>.react-tabs__tab-list .react-tabs__tab {
  padding: 12px 22px !important;
  margin-left: 10px;
  background: #023786;
  color: #93959b;
  border-bottom: none !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
}

.meeting_task_due_date .react-datepicker__input-container>.form_input {
  padding: 0.4rem 0.75rem;
  border: 1px solid #000;

}

.edit-button {
  padding: 3px 3px;
  background: transparent;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  text-transform: capitalize;
  /* Primary button color */
  color: #232323;
}

.edit-button-account {
  padding: 3px 3px;
  background: transparent;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-transform: capitalize;
  /* Primary button color */
  color: #232323;
  border-bottom: 2px solid #2C67FF;
  /* identical to box height */
  text-transform: capitalize;
  /* Primary button color */
  color: #232323;
}

.edit-button-account img {
  width: 18px;
  height: 18px;
}

.edit-button img {
  width: 18px;
  height: 18px;
}

.delete-button {

  background: transparent;
  border: none;

}

.soc-br {
  border: 1px solid #F6F6F6;
  border-radius: 8px;
}


.delete-button {
  width: 18px;
  height: 18px;
}

.delete-button:hover {
  fill: red;
}

.delete-button-img:hover {
  fill: red;
}




.css-ptiqhd-MuiSvgIcon-root {
  /* display: none !important; */
  width: 30px !important;
  height: auto !important;
}

.task_description .form-control {
  font-size: 12px !important;
}

.task_description .form-floating>label {
  font-size: 12px !important;
  padding: 0.5rem !important
}

.task_description .form-floating>.form-control {
  height: calc(2.3rem + 2px) !important;
}

.task_description_cancel {
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #ffffff !important;
  color: #707895 !important;
  padding: 10px 15px !important;
}

.task_description_save {
  background: #FFFFFF;
  border: 1px solid #E2E7FF !important;
  border-radius: 8px;
  font-weight: 600 !important;
  color: #707895 !important;
  padding: 10px 15px !important;
}

.keyPeople_save {
  background: #FFFFFF !important;
  border: 1px solid #2C67FF !important;
  border-radius: 8px;
  font-weight: 600 !important;
  color: #2C67FF !important;
  padding: 10px 15px !important;
}


.accordion-button:not(.collapsed) {
  color: #4F5366 !important;
}

.no_border {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none !important;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.no-border {
  border-bottom: none !important;
  color: #7350EA !important;
  background: none !important;
  font-weight: 600 !important;
}

.iconTopbar {
  width: 20px !important;
  height: auto !important;
}

.mobHeader {
  z-index: 11;
}

.zIndex {
  z-index: 1;
}

.userNameList {
  color: rgb(79, 84, 102);
  font-size: 14px !important;
}

.tCustom>tbody>tr>td {
  font-size: 12px;

}


.tCustom>thead>tr>th {}

.w-20Per {
  width: 20%;
}

.tabTransaction {
  height: 100vh;
  background: #FDFAFE;
  padding-top: 2rem;
}

.meetingHeading {
  color: #4F5466;
  font-size: 20px;
  margin-bottom: 0;
}

.borderFeild {}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.w-20px {
  width: 20px;
}

.accordion-item {
  border-radius: 8px !important;
}

@media (min-width: 576px) {
  .mobHeader {
    display: none;
  }
}

.ag-chart-wrapper canvas {
  width: 100%;
  height: auto !important;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 10px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
  height: 3px !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

.mobile-fixed-top {}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 780px) {
  .desktop_view {
    display: none !important;
  }
}

@media (max-width: 576px) {

  .desktop_view {
    display: none !important;
  }

  .meeting_inner_right_info {
    max-height: calc(100vh - 600px) !important;
  }

  .inner-page-header {
    padding-top: 85px !important;
  }

  .tabs-wrapper {
    padding: 20px !important;
  }

  .font-22 {
    font-size: 18px !important;
  }

  .font-mob-12 {
    font-size: 12px !important;
  }

  .logo-center {
    text-align: center;
  }

  .login-welcome-sub-title {
    line-height: 21px !important;
  }

  .rounded-13 {
    border-radius: 6px !important;
  }

  .btn-lg {
    padding: 15px !important;
  }

  .userMobile {
    text-align: right !important;
  }

  .mobHeader {
    z-index: 11;
  }

  .iconTopbar {
    width: 20px !important;
    height: auto !important;
  }

  .zIndex {
    z-index: 1;
  }


  .scroll-check {
    overflow-y: scroll;
    max-height: calc(100vh - 160px) !important;
  }

  .tradeCustom>thead>tr>th {
    min-width: 210px !important;
  }

  .w-mob-100 {
    width: 100% !important;
  }

  .idStatus {
    width: 135px;
  }

  .division-listin-process-pill {
    font-size: 9px;
    padding: 8px 10px;

  }

  .width-sm {
    width: 100% !important;
  }

  .meetings_header {
    background: #FFFFFF;
    border-width: 1px 0px;
    border-style: solid;
    height: 256px;
    border-color: #EDEFF2;
    width: 100%;
    border-top: 1px solid #EDEFF2;
    box-shadow: -4px -2px 40px 15px rgba(0, 0, 0, 0.05);
    margin-top: 4.7rem;
  }

  .form-floating>.form-control,
  .form-floating>.form-select {
    height: calc(3rem + 2px);
    line-height: 1.25;
  }

  .meeting_date_select {
    margin-left: 2px;
  }

}

.z-999 {
  z-index: 999 !important;
}


.scroll-check {
  overflow-y: scroll;
  max-height: calc(100vh - 160px) !important;
}

/* devices width */
/* @author Sajith */

@media (max-width: 768px) {

  .trade_process_tabs .react-tabs__tab-list .react-tabs__tab--selected {
    padding: 13px 10px !important;
  }

  .scrollTradeTrasnaction {
    overflow: scroll;
  }

  /* width */
  ::-webkit-scrollbar {
    height: 2px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(194, 194, 194);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(194, 194, 194);
  }

  /* .modal{
  width: auto !important;
}
.modal-content{
  width: auto !important;
} */

  .tabTransaction {
    display: flex;
    height: 200px;
    width: 352px;
  }

  /* downloadcss */
  .w-200 {
    width: 200px;
  }

  .w-150 {
    width: 150px;
  }

  .w-120 {
    width: 120px;
  }

  /* download css */

  /* dashboard css */

  .react-tabs__tab-list {
    /* display: flex; */
  }

  .Greeting_greetingContainer__yFN9c {
    margin-bottom: 20px;
  }

  .Trends_trendBody__2Y8f8 {
    margin-bottom: 20px;
  }

  .Trends_trendContainer__m7xuS {
    margin-bottom: 20px;
  }

  /* .css-1tu4ad6{
      border: transparent !important;
    } */

  /* css dashboard */
  .tUsers .add-edit-wrapper {
    padding: 0 0.7rem !important;
  }

  .w-mob-100 {
    width: 100% !important;
  }

  .inner-page-content {
    padding: 10px !important;
  }

  .tUsers>thead,
  tbody,
  tfoot,
  tr,
  td,
  th,
  h4 {
    color: rgb(79, 84, 102);
    font-size: 14px !important;
  }


  .tabs-wrapper {
    padding: 20px !important;
  }

  .react-tabs__tab {
    width: 100% !important;
    padding: 15px 20px !important;

  }

  .mobHeader {
    z-index: 11;
  }

  .zIndex {
    z-index: 1;
  }

  .inner-page-content {
    padding: 1rem !important;

  }

  .iconTopbar {
    width: 20px !important;
    height: auto !important;
  }

  .top10 {
    top: 10%;
  }

  .font-22 {
    font-size: 18px !important;
  }

  .font-mob-12 {
    font-size: 12px !important;
  }

  .logo-center {
    text-align: center;
  }

  .login-welcome-sub-title {
    line-height: 21px !important;
  }

  .rounded-13 {
    border-radius: 6px !important;
  }

  .btn-lg {
    padding: 15px !important;
  }

  .userMobile {
    text-align: right !important;
  }

  .userList>thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    padding: 3px 5px !important;
  }

  .status_badge {
    padding: 5px 4px !important;
    border-radius: 6px !important;
    width: 160px;
  }

  .disabled_badge {
    width: 200px;
    padding: 5px 4px !important;
    border-radius: 6px !important;
  }

  .btn-secondary {
    font-size: 15px !important;
    border-radius: 6px !important;
  }

  .modal.show .modal-dialog {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .tradeCustom>tbody>tr>td {}

  .tradeCustom>thead>tr>th {
    min-width: 210px !important;
  }

  .tradeInitiation>thead>tr>th {
    min-width: 165px !important;
  }



}

@media (min-width: 1400px) {

  .border-bottom-primary {
    padding: 6px;
  }

  .container {
    max-width: 1440px !important;
  }



}





@media screen and (max-width: 1366px) and (min-width: 1200px) {

  .border-bottom-primary {
    padding: 0;
    border: transparent;

  }



}



/* devices width */



.mb-2 {
  margin-bottom: 2rem !important;
}

.inner-content {
  padding: 2rem;
}

.scroll-over .react-tabs__tab-list {
  max-height: calc(100vh - 70px) !important;
}

.rbc-time-slot {
  background: #FFFFFF !important;
}

.rbc-timeslot-group {
  min-height: 81px !important;
}


.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none !important;
}

.btn-check:checked+.btn-outline-primary {
  background-color: #2C67FF !important;
  color: #fff !important;
}

.btn-outline-primary:active {
  background-color: transparent !important;
  color: #fff !important;
}

.meeting_date {
  padding: 0.2rem 0 8px 0rem !important;
}

.meeting_search {
  padding: 3rem 3rem
}

.meetingtitle_view {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3A3A3A;
}

.meetingdate_view {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #858A98;
}

.meetingtype_view {
  background: #E2E7FF;
  border: 1px solid #2C67FF;
  border-radius: 50px;
  color: #555555;
  padding: 4px 10px;
}

.meeting_vew_top_header {
  background: #FFFFFF;
  padding: 3rem 3rem;
}

.meeting_innner_info {
  margin: 3rem 1rem 0 1rem;
}

.meeting_inner_left_info {
  background: #fff;
  border: 1px solid #EDEFF2;
  border-radius: 10px 0px 0px 10px;
  padding: 2rem 2rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #2C67FF;
}

.meeting_time {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: #9095A2;

}

.meeting_inner_right_info {
  padding: 1rem 2rem;
  background: #fff;
  max-height: calc(100vh - 336px);
  overflow-y: scroll;

}

.meeting_category_title {

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #5B656D;
}

.meeting_hosted_by {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #62668B;
}

.meeting_content_view .react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  padding: 12px 22px !important;
  cursor: pointer;
}

.meeting_content_view .react-tabs__tab-list .react-tabs__tab--selected {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 3px solid #7350EA !important;
  color: #7350EA !important;
  background: none !important;
  padding: 12px 22px !important;
  font-weight: 600 !important;
}

.meeting_accordin {

  border-radius: 13px !important;
  /* margin: 0 2rem; */
}

.meeting_accordin_header .accordion-button {
  background: #F9F9FF !important;
}

.meeting_agendaname {

  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #5B656D !important;
}

.meeting_accordin .accordion-button:focus {
  z-index: 3;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important
}

.meeting_inner_task_heading {
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: #62668B !important;
}

.meeting_inner_task_date {
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 16px !important;
  color: #5B5F6E !important;

}

.chip_meeting_task_user {
  background: #EAEAEA !important;
  border-radius: 63.5417px !important;
  height: 40px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
  color: #4F5366 !important;
}

.task_button {
  border: 1px solid #707895 !important;
}

.task_button_attachment {
  border: 1px solid #707895 !important;
  padding: 0.375rem 0.25rem;
  border-radius: 13px !important;
}

.swiper-container {
  height: 100%;
  max-height: 100vw;
  /* // CSS Grid/Flexbox bug size workaround
  // @see https://github.com/kenwheeler/slick/issues/982 */
  min-height: 0 !important;
  min-width: 0 !important;
  max-width: 100vw !important;
  width: 100% !important;
  overflow: hidden !important;
}

.swiper-slide {
  width: auto;
  flex-shrink: 0;
  display: block;
  height: 100%;
  max-height: 100%;
}

.swiper-wrapper {
  max-height: 100%;
  height: 100%;
  display: flex;
}

.meeting_task_comment_section {
  padding: 1rem 3rem;
}

.comment_box {
  background: #F5F4F4 !important;
  border-radius: 8px !important;
  padding: 0.5rem !important;
  margin: 1rem 3rem !important;
}

.user_info_meeting_comments {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #14161B !important;
}

.user_designation_meeting {
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 16px !important;
  color: #62668B !important;
}

.meeting_comments_data {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #515B65;
}

.w-20px {
  width: 20px !important;
}

.faq .accordion-button {
  padding: 1rem 1.25rem !important;
}

.faq .accordion-item {
  border-radius: 0 !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: white !important;
  text-transform: capitalize !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}

.custom-form-select>.form-select {
  width: auto !important;
  font-size: 1rem !important;
}


.bg-white {
  background-color: #fff !important;
}

.reports_top {
  height: 280px;
  box-shadow: #000;
}

.reports_count_header {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-transform: uppercase !important;
  color: #9FADC7 !important;

}

.rt-b {
  border-right: 1px solid #16161633 !important;
  height: 200px
}

.rt-sm-b {
  border-right: 1px solid #16161633 !important;
  height: 92px;
}

.reports_count_value {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 48px !important;
  line-height: 56px !important;
  color: #383874 !important;
}

.reports_count_pre_suf {
  font-weight: 400 !important;
  font-size: 48px !important;
  line-height: 42px !important;
  text-transform: uppercase !important;
  color: #69789F !important;
}

.sec1 {
  background-image: url('../public/assets/images/customerReportSec1.svg');
  background-repeat: no-repeat;
}

.sec2 {
  background-image: url('../public/assets/images/customerReportSec2.svg');
  background-repeat: no-repeat;
}

.sec3 {
  background-image: url('../public/assets/images/customerReportSec3.svg');
  background-repeat: no-repeat;
}

.sec4 {
  background-image: url('../public/assets/images/supports/dashboard-trade-overview-icon.svg');
  background-repeat: no-repeat;
}

.sec5 {
  background-image: url('../public/assets/images/trade.svg');
  background-repeat: no-repeat;
}

.sec6 {
  background-image: url('../public/assets/images/shipment.svg');
  background-repeat: no-repeat;
}



@font-face {
  font-family: 'gilroyregular';
  src: url('/src/assets/font/Gilroy-Regular.woff')format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroylight';
  src: url('/src/assets/font/Gilroy-Light.woff')format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroymedium';
  src: url('/src/assets/font/Gilroy-Medium.woff')format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gilroysemibold';
  src: url('/src/assets/font/Gilroy-SemiBold.woff')format('woff');
  font-weight: normal;
  font-style: normal;
}


.gil-semibold {
  font-family: 'gilroysemibold' !important;
  font-weight: 600;
}

.gil-medium {
  font-family: 'gilroymedium' !important;
  font-weight: 500;

}

.gil-light {
  font-family: 'gilroylight' !important;
  font-weight: normal;

}

.gil-regular {
  font-family: 'gilroyregular' !important;
  font-weight: 400;
}

.shipmentsec1 {
  background-image: url('../public/assets/images/shipmentReportSec1_2.svg');
  background-repeat: no-repeat;
}

.ReportslossIcon {
  padding: 6px 8px;
  background: #FC5980;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.ReportsprofitIcon {
  padding: 6px 8px;
  background: #3AA345;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  border-bottom: 1px solid #000 !important;
}

.css-19kzrtu {
  padding: 5px 0 0 0 !important
}

.report_filter_title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #62668B;
}

.date_range_filter {
  background: #FFFFFF;
  border: 1px solid #A7A7A7;
  border-radius: 10px;
  height: 50px;
  padding: 1rem;
}

.b-left {
  border-left: 1px solid #EFEEF1;
  padding: 0 10px !important;
}

.div-scroll {
  overflow-y: scroll;
  height: 164px;
}

.react-datepicker-popper {
  z-index: 999 !important;
}



.details_title {
  height: 50px;
  background: #F8F9FB;
}

.rpt_pdf {
  background-image: url('../public/assets/images/icon_pdf.svg');
  background-repeat: no-repeat;
}

.reports_details .modal-title {
  width: 100%;
}

.boXshadow {
  box-shadow: 25px 26px 40px rgba(230, 230, 230, 0.25);
}

.faQ.accordion-button::after {
  display: none !important;
}

.performance_overview {
  height: 200px;
  background: #ffffff;
}

.performance_overview_title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #69789F;

}

img.delete-button-img {
  width: 22px !important;
  height: 22px !important;
}

.modal-footer {
  background-color: #fff;
}

.ag-chart-wrapper canvas {
  width: 600px;
  margin-top: -48px;
}

.tab_data {
  margin-top: -16px !important;
}

.mobile-fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999 !important;
}

.Bt-disable {
  margin-top: 0px !important;
}

.list-scroll {
  overflow-y: auto !important;
  max-height: calc(100vh - 248px) !important;

}

.tran-scroll {
  overflow-y: auto !important;
  max-height: calc(100vh - 340px) !important;

}

.list-top {
  top: 0px !important;
  position: sticky;
  z-index: 1000;
}

.uline {
  text-decoration: underline;
}

.invoice_border {
  border: 1px solid #000;
  outline: 1px solid #000;
  outline-offset: -3px;
}

.multiple-trades-header {
  font-family: 'gilroyregular';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4F5466
}


.multiple-trades-company-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #343434;
}

.multiple-trades-company-text {
  font-family: 'gilroyregular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.view_meeting .modal-content {
  background-color: #F9F9FF !important;
}

.mt-8r {
  margin-top: 8.8rem !important;
}

.customer_selection_block {
  border: 1px solid #eaeaea;
  padding: 1rem;
  border-radius: 10px;
}

.alert-warning {
  z-index: 100 !important;
  top: 60px;
  padding: 0.45rem 0.25rem !important;
  background-color: rgb(255, 206, 34) !important;
}

.alert-dismissible .btn-close {
  padding: 0.8rem 1rem !important;
  width: 0.45rem !important;
  height: 0.45rem !important;
}

.alert-dismissible .btn-close:focus {
  box-shadow: none !important;
}

.multicompany-split-ratio-modal-text {

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  text-transform: capitalize;

  /* Gray/2 */

  color: #2C2C2E;
}

.titleHeader {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #69789F;
}

.intervals {
  border: none !important
}

.list-scroll-user {
  overflow-y: auto !important;
  max-height: calc(100vh - 617px) !important;
}

.link_icon_bg {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #F0F5F8;

}

.link_icon_bgs {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #F0F5F8;
  padding: 3% 3%;
}

.http_linkssss {
  width: 70px !important;
  height: 58px;
  background: #FCFEFF;
  border: 1px solid #A7A7A7;
  border-radius: 13px 0px 0px 13px;
}

.left_no_padding {
  padding-left: 0 !important;
}

.left_no_padding .form-control {
  border-radius: 0px 13px 13px 0px !important;
}

.no_padding_left {
  padding-left: 0 !important;
  width: 90% !important;
  margin-bottom: 1rem;
}

.rounded_border_tabs {
  border: 1px solid #DADCE0;
  border-radius: 8px;
}

.achievement_badge {
  width: 112px;
  height: 32px;
  background: #F9F9FF;
  border: 0.4px solid #E7E8EB;
  border-radius: 6px;
}

.scroll-cont {
  overflow-y: auto !important;
  max-height: calc(100vh - 500px) !important;
}

.cont-top {
  top: -16px !important;
  position: sticky;
  z-index: 1000;
}

.btn-sm {
  padding: 14px 24px !important;
}

.free-solo {
  border: 0.8px solid #A7A7A7 !important;
  border-radius: 13px !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.2rem !important;
  padding-left: 0.9rem !important;
}

.free-solo-add {
  border: 0.8px solid #A7A7A7 !important;
  border-radius: 13px !important;
  padding: .16rem 0.75rem !important;
  padding-left: 0.9rem !important;
}


.br-trans {
  padding: 1px 0px 2px 2px !important;
}

.br-trans::after {
  padding: 4px 0px 4px 4px !important;
}


.br-trans .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid transparent !important;
}

.br-trans .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: 'Poppins' !important;
}

.br-trans .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  padding: 0.5rem 0.2rem !important;
}

.br-trans .css-1qqsdnr-MuiAutocomplete-root .MuiInput-root .MuiInput-input::after {
  padding: 8px 4px 4px 0px !important;
}

.br-trans .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  color: #4F5466 !important;
  line-height: 1.2em !important;
}

.br-trans .css-ptiqhd-MuiSvgIcon-root {
  display: none !important;
}

.meet-title .form-floating>label {
  padding: 0.8rem 0rem !important;


}

.meet-title .form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {

  transform: scale(0.85) translateY(-0.5rem) translateX(0rem) !important;
  font-size: 14px !important;
  color: #4F5466 !important;
  font-weight: 500 !important;

}

.meet-title .form_input {
  font-size: 18px !important;
}

.meet-title .css-1a1fmpi-MuiInputBase-root-MuiInput-root {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-family: 'Poppins' !important;
}


.typeMeet .css-1a1fmpi-MuiInputBase-root-MuiInput-root {
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: 'Poppins' !important;
}

.typeMeet .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: 'Poppins' !important;
}

.typeMeet .css-1qqsdnr-MuiAutocomplete-root .MuiInput-root .MuiInput-input {
  padding: 4px 4px 8px 0px !important;
}

/* .typeMeet .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: 'Poppins' !important;
} */

.meet-title .form-floating>.form-control {
  padding: 2.5rem 0rem 1rem 0rem !important;
}

.meet-time .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(0px, -9px) scale(0.75) !important;
}

.meet-time .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16px 0px 4px 0px !important;
  font-size: 16px !important;
  font-family: 'Poppins' !important;
}

.meet-time .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  font-family: 'Poppins' !important;
  letter-spacing: 0em !important;
  color: #4F5466 !important;
}

.meet-date .react-datepicker__input-container>.form_input {
  font-size: 15px !important;
  color: #4F5466 !important;
}

.meetingrpt_table {
  border: 1px solid;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiAutocomplete-tag {
  margin: 8px 8px 8px 0px !important;

}

.css-1q79v3g-MuiButtonBase-root-MuiChip-root {
  background-color: #E4EBFF !important;
}

span.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
  background-color: #E4EBFF !important;
  padding: 8px !important;
  border-radius: 80px !important;
  width: 36px !important;
  height: 36px !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
  color: #2C67FF !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.addAgenda .form-floating>.form-control {
  height: calc(2.3rem + 14px) !important;
}

.addAgenda .form-control {
  font-size: 16px !important;
}

.host-con {
  width: fit-content !important;
}

.bg-meetCard {
  background: #F9F9FF !important;

}

.bg-points {
  background-color: #F6F6F6 !important;
}

.br-meetBorder {
  border-bottom: 1px solid #EDEDEF;
  padding-bottom: 16px !important;
}

.meet-icon {
  width: 24px;
  height: 24px;
}

.bg-meetExpo {
  background-color: #C8C8C8 !important;
}

.br-rightMeet {
  border-right: 1px solid !important;
}

.keyHeader .accordion-button:not(.collapsed) {
  background-color: #F9F9FF !important;
  box-shadow: none !important;
}

.keyHeader .accordion-button {
  background-color: #F9F9FF !important;
}

.react-datepicker__year-dropdown {
  background-color: #F9F9FF !important;
}

.react-datepicker__year-option:hover {
  background-color: #dde6f7 !important;
}

.react-datepicker__year-option {
  padding: 4px 0px !important;
  font-size: 14px !important;
}

.react-datepicker__year-option.react-datepicker__year-option--selected_year {
  background-color: #D8E5FF !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}

span.react-datepicker__year-option--selected {
  display: none !important;
}

a.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  content: "\F148" !important;
  color: #000000 !important;
  font-weight: 400 !important;
}

.bg_landing {
  background: #EEEFFF;
  ;
  height: 70vh;
  margin: 0;
  padding: 0;
  background-image: url('../public/assets/images/tmslanding/yellowbg.svg');
  background-repeat: no-repeat;
  background-position: right;
}

.landing_menu {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding: 0px 2%;
}

.landing_demo_button {
  background: #408AFB;
  border-radius: 32px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #FFFFFF !important;
  padding: 10px 18px !important;
}

.landing_demo_button a {
  color: #FFFFFF !important;
}

.landing_demo_button_white {
  border: 2px solid #408AFB !important;
  border-radius: 32px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #408AFB !important;
  padding: 10px 18px !important;
}

.landing_choose_text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 140%;
  color: #10223D;
}

.landing_trade_text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 140%;
  color: #10223D;
}

.landing_small_text {

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  color: #52525B;
}

.landing_small_texts{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  color: #52525B;
}

.landing_top_image_right {
  background-image: url('../public/assets/images/tmslanding/img1.png');
  position: absolute;
  background-size: cover;
  height: 80%;
  width: 100%;
  right: 10%;
}

.landing_100 {

  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  color: #10223D;
}

.landing_second_section {
  margin-top: 10rem;
}

.landing_second_section_left_heading {
  font-weight: 600;
  font-size: 34px;
  line-height: 140%;
  color: #10223D;
  margin-top: 5rem;
}

.landing_left_hr hr {
  background: #408AFB;
  width: 16px;
  height: 3px !important;
  opacity: 1;
}

.landing_left_inner_heading {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #0A2540;
}

.landing_left_regular {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #425466;
}

.landing_explore_more {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #408AFB;
  margin-top: 1rem !important;
}

.bg-footer {
  background-color: #1C1C1C !important;
}

.txt-ftr {
  color: #ffffff !important;
}

.bg-last {
  background-color: #323232 !important;
}

.landing_modal_contact {
  font-weight: 500;
  font-size: 26px;
  line-height: 24px;
  color: #343434;
}

.PhoneInput input {
  border: none !important;
}

.total_sub {
  margin-top: 25%;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #9FADC7;
}

.total_count {
  font-size: 48px;
  line-height: 56px;
  color: #383874;
}

.subs_over {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #69789F;
}

.good_mng{
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #9FADC7;
}